.contact-us-form {
    width: 100%;
    max-width: 750px;
    min-height: 535px;
    border-radius: 120px 0;
    opacity: 0.8;
    background: #FFF;
}

.contactUsFormLft h4 {
    color: #454545;
    font-family: "source-sans-pro";
    font-size: 18px;
    font-weight: 600;
    font-style: normal;
    margin-bottom: 0;
}

.contactUsFormLft span a {
    font-family: 'Sintony', sans-serif;
    color: #0035EF;
    font-size: 12px;
}

.contactUsFormLft h3 {
    color: #0035EF;
    font-family: "source-sans-pro";
    font-size: 18px;
    font-style: normal;
    margin-left: 30px;
}

.contactUsFormWrapper input {
    box-shadow: none !important;
    font-size: 14px;
    height: 35px;
    border: 1px solid #dee2e6 !important;
}

.contactUsFormWrapper1 input {
    height: 40px;
}

.contactUsFormLft address {
    color: #454545;
    font-family: "source-sans-pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

@media screen and (max-width: 991px) {
    .contactUsFormWrapper {
        flex-direction: column-reverse;
    }
}

@media screen and (max-width: 767px) {
    .contact-us-form .row-head {
        padding-top: 10px !important;
    }

    .contactUsFormWrapper {
        flex-direction: unset;
    }

    .contact-us-form .row-head h6 {
        display: none;
    }

    .contactUsFormWrapper {
        flex-direction: column-reverse;
    }

    .contact-us-form {
        padding: 0 30px !important;
    }

    .contact-us-form {
        max-width: 85%;
        border-radius: 70px 0;
        margin: auto;
    }

    .contactUsFormWrapper .content-btn {
        min-width: 185px;
        height: 40px;
    }

    .contactUsFormLft {
        margin-top: 20px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-content: center;
    }

    .contactUsFormLft .address-section {
        display: none !important;
    }

    .contactUsFormLft h3 {
        display: none;
    }
}

@media screen and (max-width: 585px) {
    /* .contact_us_action_center {
        width: 100%;
        justify-content: center;
    } */
}