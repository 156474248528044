.collection-slider {
    border: 24px solid #69B1D6;
}

.collection-slider-wrapper {
    border: 2px solid #000;
    font-family: "source-sans-pro";

}

.collection-slider-wrapper p {
    color: #454545;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}


.collection-slider-wrapper p span {
    color: #3E7B8D;
    font-weight: 600;
}

.collection-slider-wrapper h2 img {
    max-width: 100%;
    width: 200px;
    height: auto;
}

.collection-slider-wrapper .swiper-slide {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.collection-slider-wrapper .collection-slider-wrapper-logo {
    position: absolute;
    top: 50px;
    right: 30px;
    max-width: 260px;
    height: auto;
}

/* 
.collection-slider-wrapper .collection-img img{
    max-width: 30%;
    max-height: 400px;
}

img.co-img1 {
    min-height: 520px !important;
    margin-top: -110px !important;
}

img.co-img2 {
    height: 320px;
    width: 500px;
} */

.collection-slider-inner-wrapper img {
    max-width: 100%;
    width: 100%;
    height: 450px;
}

.collection-slider-inner-wrapper .slick-dots {
    bottom: 30px;
}

.collection-slider-inner-wrapper .slick-dots li {
    margin: 0;
}

.collection-slider-inner-wrapper .slick-prev {
    display: none !important;
}

.collection-slider-inner-wrapper .slick-next {
    display: none !important;
}

.slick-dots li button:before {

    font-size: 12px;
}

.slick-dots li.slick-active button:before {

    font-size: 18px;
}

.collection-slider-logo {
    width: 120px;
    position: absolute;
    bottom: 15px;
    left: 15px;
}

.collection-slider-inner-wrapper {
    width: 90%;
    margin: 0 auto;
}


@media only screen and (max-width: 767px) {
    .collection-slider-inner-wrapper {
        width: 100%;
        margin: 0 auto;
    }

    .collection-slider-inner-wrapper .slick-dots {
        bottom: -25px;
    }
}