.product-contact-us-wrapper .contact-us-form {
    margin-top: 0;
    box-shadow: 0px 8px 39px 0px rgba(0, 0, 0, 0.08);
}

.no-product-txt h2 {
    color: #454545;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 400;
}

.no-product-txt p {
    font-family: "source-sans-pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
}

.no-product-bg-grey { 
    width: 100%;
    background: #F6F3EE;
    height: 230px;
}

.product-contact-us-wrapper1 { 
    position: relative;
    margin-top: -150px;
    width: 100%;
}

@media screen and (max-width:768px) {
    .no-product-txt h2 {
        font-size: 30px;
    }
    .no-product-txt p {
        width: 100% !important;
        font-size: 16px;
    }
}
