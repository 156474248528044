
.trend-slider .product-card {
    width: 100%;
}

@media only screen and (max-width:767px) {
    .trend-slider-wrapper { 
        padding-bottom: 20px !important;
        margin-bottom: 10px !important;
    }
    .trend-slider-wrapper .content-btn {
        min-width: 190px;
        max-width: 210px;
    }
    .trend-slider .product-card {
        width: 100%;
        min-height: 305px;
    }
    .product-card .product-img {
        min-height: 190px !important;
        width: 100%;
        height: 190px;
    }
    .product-card .descrip {
        margin-bottom: 5px;
    }
    .product-card .sku-text {
        margin-top: unset !important;
    }
    .product-card .view-product {
        height: 37px;
    }
}
@media only screen and (max-width:767px) {

    .trend-slider .product-card {
        min-height: 290px;
    }
}