.home-banner {
        /* background-image: url('../../../../assets/images/homeBanner.png');
        background-size: cover;
        background-repeat: no-repeat; */
    position: relative;
    min-height: 600px;
    overflow: hidden;
}

.blacklist-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Adjust the last value (opacity) as needed */
    z-index: 1;
  }
.home-banner .brands-logo {
    position: absolute;
    bottom: 30px;
    z-index: 2;
}
.brands-logo img { 
    max-width: 90%;
}

.home-banner .list-group-item {
    background-color: unset;
    border: unset;
}

.home-banner-inner span img {
    max-width: 260px;
    object-fit: cover;
    position: relative;
    z-index: 2;
}

video::-internal-media-controls-overlay-cast-button {
    display: none;
}

@media only screen and (max-width:580px) {
    /* .brands-logo{
        display: none !important;
    } */
    .home-banner {
        height: 600px;
    }
    .home-info-lft  .carousel-control-prev{
        display: none   ;
    }
    .home-banner .list-group-item:nth-last-child(-n+3) { 
        display: none;
    }
    .brands-logo img {
        max-width: 100%;
    }
    .home-banner .list-group-item { 
        width: 60px !important ;
        padding: 0 10px;
    }

    .home-banner .list-group-item:nth-child(1), .home-banner .list-group-item:nth-child(2),  .home-banner .list-group-item:nth-child(5) {
        width: 100px !important;
    }
}


/* .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: auto;
    z-index: -2;
} */



.video-container {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
    overflow: hidden;
    z-index: 0;
    direction: ltr;
}

.video-container video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    max-width: 100%;
    width: 100%;
    margin: 0;
    line-height: 1;
    border: none;
    object-fit: cover;
}

@media screen and (max-width: 767px) {
    .home-banner-inner span img {
        max-width: 200px;
    }
}