@font-face {
    font-family: "Brown";
    src: url("../fonts/Brown/Brown-Regular.ttf") ;
}
@font-face {
    font-family: "quesha-font";
    src: url("../fonts/quesha-font/Quesha-gndR.ttf");
}
@font-face {
    font-family: "source-sans-pro";
    src: url("../fonts/source-sans-pro/SourceSansPro-Regular.ttf");
}

@import url('https://fonts.googleapis.com/css2?family=Sintony:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,600;0,700;0,800;0,900;1,400;1,600;1,700;1,800;1,900&family=Sintony:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200;0,6..12,300;0,6..12,400;0,6..12,500;0,6..12,600;0,6..12,700;0,6..12,800;0,6..12,900;0,6..12,1000;1,6..12,200;1,6..12,300;1,6..12,400;1,6..12,500;1,6..12,600;1,6..12,700;1,6..12,800;1,6..12,900;1,6..12,1000&display=swap');

* {
    margin: 0;
    padding: 0;
}

body { 
    font-family: "Brown";
}

/* font-family: 'Playfair Display', serif; */

@media (min-width: 1200px) {
.container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1450px;
}
}