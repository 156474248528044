.product-card {
    width: 24%;
    border: 1px solid #E7E7E7;
    background: #FFF;
    box-shadow: 0px 0px 12px 0px rgba(210, 210, 210, 0.20) inset;
    font-family: "source-sans-pro";
    /* margin-bottom: 30px; */
}

.product-card .product-img {
    /* min-height: 290px; */
    height: 100%;
    /* object-fit: contain; */
    /* object-fit: cover; */
    object-position: center;
    transition: opacity 0.3s ease-in-out;
}

.brand_logo {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 40px;
    z-index: 1;
}

.product-card .descrip {
    color: #454545;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    text-transform: capitalize;
    min-height: 48px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-card .card-title {
    font-size: 20px;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
}

.product-card .sku-text {
    color: #454545;
    font-family: "source-sans-pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    display: -webkit-box;
    max-width: 400px;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-card .view-product {
    /* visibility: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    opacity: 0.7;
    background: #454545;
    display: flex;
    width: 100%;
    height: 45px;
    align-items: center;
    justify-content: center;
    font-family: "source-sans-pro"; */

    /* background: #454545; */
    text-align: center;
    width: 100%;
    height: 45px;
    position: absolute;
    padding: 10px;
    bottom: 0;
    width: 100%;
    visibility: hidden;
    opacity: 0;
    transition: visibility .2s ease-in-out, opacity .2s ease-in-out, transform .2s ease-in-out, .2s ease;
    transform: translateY(16px);

    padding: 0.7em 1.5em;
    border: none;
    color: #fff;
    background: linear-gradient(to bottom, #454545 50%, #000 50%);
    background-size: 100% 200%;
    background-position: left top;

}

.view-product:hover {
    background-position: right bottom;
}


.product-card:hover .view-product {

    visibility: visible;
    opacity: 1;
    transform: translateY(0);

}

.product-card .view-product a {
    text-decoration: none;
    color: #fff;
}

.product-card .position-relative {
    position: relative;
}

.product-card .image-container {
    position: relative;
    overflow: hidden;
    min-height: 260px;
    cursor: pointer;
}

.product-card .product-img,
.product-card .hover-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    object-position: center;
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.product-card .hover-img {
    opacity: 0;
}

.product-card:hover .product-img {
    opacity: 0;
}

.product-card:hover .hover-img {
    opacity: 1;
}

.image-tag {
    position: absolute;
    top: 10px;
    right: 15px;
}

.heart-btn:hover {
    cursor: pointer;
}
.product-logo{
    position: absolute;
    top:0;
    left:0
}

@media screen and (max-width: 767px) {
    .product-card .image-container {
        min-height: 160px;
    }

    .product-card .image-container img {
        object-fit: cover;
    }

    .no-product-wrapper {
        padding: 0 20px !important;
    }
    
    .no-product-wrapper h2 {
        font-size: 30px;
    }
    
}

@media screen and (max-width: 480px) {
    .product-card .card-body {
        padding: 10px ;
    }
    .view-product {
        display: none;
    }
}