.signup-modal .modal-content {
    border-radius: 120px 0;
    background: #FFF;
}

.signup-modal .btn-close {
    position: absolute;
    right: 30px;
    top: 20px;
}

.pass-input {
    padding-right: 45px !important;
}

.icon {
    position: absolute;
    right: 15px;
}

.signup-modal h2 {
    color: #000;
    font-family: 'Playfair Display', serif;
    font-size: 42px !important;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
}

.close-btnn{
    background: transparent !important;
    border: none !important;
    outline: none !important;
    color: #000 !important;
    position: absolute;
    top: 10px;
    right: 10px
}

.signup-modal input {
    border-radius: 7px;
    background: #fff !important;
    height: 45px;
    border: 1px solid #DED2D9 !important;
}

.signup-modal textarea {
    border-radius: 7px;
    border: 1px solid #DCDCDC;
    background: #fff !important;
    color: #B0B0B0;
}


.loginn_Btn {
    background: unset !important;
    outline: unset !important;
    border: unset !important;
    color: #454545 !important;
    margin-left: 5px;
    padding: unset;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
}

.signup-modal .form-floating input {
    background: #fff !important;
    height: 50px;
    min-height: 50px;
    padding: 5px .75rem;
    border: 1px solid #DED2D9;
}

.signup-modal .form-floating label {
    padding: 5px .75rem;
    display: flex;
    color: #B0B0B0;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    align-items: center;
}

.signup-modal .form-floating label::after {
    display: none
}

.register-btn {
    width: 250px;
}

.signup-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.signup-text span {
    text-transform: capitalize;
    font-family: "Brown";
    line-height: normal;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: #A1A1A1;
    position: relative;
}

.signup-text span::before {
    content: "";
    position: absolute;
    left: -85%;
    width: 70%;
    height: 1px;
    top: 8px;
    background-color: #DDD;
}

.signup-text span::after {
    content: "";
    position: absolute;
    right: -85%;
    width: 70%;
    height: 1px;
    top: 8px;
    background-color: #DDD;
}

.PhoneInputCountry {
    border-radius: 7px 0px 0px 7px;
    background: #454545;
    margin-right: unset !important;
    width: 15% !important;
    justify-content: center !important;
    color: #fff;
}

.PhoneInputInput {
    border-radius: 0 7px 7px 0px !important;
    text-indent: 5px;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    border: unset !important;
    outline: unset !important;
}

.PhoneInputInput::placeholder {
    color: #B0B0B0 !important;

}

.captchacard span {
    color: #454545;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}

.captchacard label {
    color: #454545;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.socialImg img {
    width: 30px;
    margin: 0 5px;
    height: auto;
}

.socialImg html {
    text-align: center;
}

.captchacard {
    width: 340px;
}

.captchacard .card {
    background: whitesmoke;
    padding: 20px;
}

.captchacard input {
    background-color: white !important;
    border-radius: unset;
    height: unset;
}

.captchacard .h3 {
    text-align: center;
    background-color: lightblue;
    padding: 10px 0;
}

.error_message {
    color: red;
    margin-top: -10px;
}

.sign-up-left { 
    background: #000;
    display: flex;
    align-items: center; 
    min-height: 100vh; /* Use a minimum height */
}

.sign-up-left  img { 
    max-width: 100%;
    width: 100%;
    height: auto; 
    display: block;
    margin: 0 auto; 
}

.responsive_logo { 
    display: none;
}



@media screen and (max-width: 991px) {
    .signup-modal h2 {
        font-size: 30px !important;
    }
    .close-btnn {
        font-size: 22px;
    }
    .responsive_logo {
        display: block;
    }
    .responsive_logo img{
        width: 150px;
    }
}

@media screen and (max-width: 575px) {
    .signup-page-outer .col-lg-5.col-sm-12 { 
        width: 100%;
    }
    .signup-page-outer .col-lg-7 { 
        display: none;
    }
    .signup-text::before {
        left: 40px;
    }
    .signup-text::after {
        right: 40px;
    }
    .signUp { 
        padding: 10px !important;
    }
    .signup-modal .modal-content{
        border-radius: 0;
    }
}