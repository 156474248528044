.dropdown-wrapper {
    background: #FFF;
    box-shadow: 0px -3px 30px 0px rgba(94, 94, 94, 0.06);
    height: 300px;
}

.dropdown-container {
    position: absolute;
    z-index: 99;
    top: 20px;
    left: 0;
    right: 0;
    max-width: 1200px;
    border-radius: 0px 0px 5px 5px;
    margin: 50px auto 0;
    padding-top:20px;
}

.dropdown-lft-cnt ul {
    margin: 0;
    padding: 0;
}



.dropdown-lft-cnt ul li img {
    margin-right: 10px;
}

.dropdown-lft-cnt h3 {
    color: #454545;
    font-family: "Brown";
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
    margin-bottom: 10px;
}


.dropdown-lft-cnt ul li a { 
    text-decoration: none;
    color: #454545;
    font-family: "Brown";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.dropdown-lft-cnt ul li {
    list-style-type: none;
    padding: unset;
   
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 5px;
}

.dropdown-rght-banner { 
    padding-right: 30px;
}

.dropdown-rght-banner img {
    max-width: 100%;
    height: 250px;
}
