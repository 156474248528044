.product-dropdown {
    position: absolute;
    top: 60px;
    background: #000;
    width: 96%;
    z-index: 9;
    min-height: 300px;
    overflow-y: auto;
}
 
 .search-form .dropdown {
    list-style: none;
    padding: 10px;
    background-color: #fff;
    position: absolute;
    width: 100%;
    margin: 0;
    border-radius: 0 0 2px 2px;
    border-top: 1px solid #e0e0e0;
    left: 0;
    z-index: 9;
    top: 50px;
    box-shadow: 2px 3px 5px -1px rgba(0, 0, 0, 0.5);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow-y: auto;
    max-height: 300px;
}
  
  .dropdown__item {
    padding: 4px 16px 4px 8px;
  }
  
  .dropdown__item.active {
    background-color: #2874f015;
  }
  
  .dropdown__item > a {
    text-decoration: none;
    font-size: 14px;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: left;
    /* overflow: hidden; */
    cursor: pointer;
    white-space: nowrap;
  }
  .dropdown__item.active > a {
    color: #5a5a5a;
  }
  
  .search-suggestion-text {
    margin-left: 8px;
  }
  .search-suggestion-text .bold {
    font-weight: 600;
  }
  .search-suggestion-text .light {
    color: #5a5a5a;
  }
  
  .search-suggestion-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 32px;
    min-width: 32px;
  }
  .search-suggestion-icon > img {
    margin: auto;
    height: 45px;
    width: 45px;
}