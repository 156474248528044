.all-brands-inner .tab-content img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
}


.div-nav-tab.nav-tabs .nav-link {
    position: relative;
    color: #C6C6C6;
    font-family: 'Brown';
    font-size: 17px;
    border: unset;
    text-transform: uppercase;
    background-color: unset;
}

.nav-tabs.div-nav-tab .nav-link.active {
    color: black;
}

.nav-tabs.div-nav-tab .nav-link.active::after {
    content: '';
    /* Create the pseudo-element for the border */
    position: absolute;
    bottom: 0;
    left: 25%;
    display: flex;
    justify-content: center;
    width: 50%;
    /* Set the width of the border */
    height: 2px;
    /* Set the height of the border */
    background-color: black
        /* Replace with your desired border color */
}

@media screen and (max-width: 1199px) {
    .all-brands-tab-outer {
        width: 100% !important;
    }

    .home-banner .list-group-item {
        width: 120px;
    }

    .brands-logo img {
        max-width: 100%;
    }
}

@media screen and (max-width: 991px) {
    .brand-card-outer-wrapper {
        padding: 0 100px;
    }
}

@media only screen and (max-width:767px) {
    .all-brands-wrapper {
        background: #F6F3EE;
    }

    .all-brands-tab-outer {
        width: 100% !important;
    }



    .div-nav-tab.nav-tabs .nav-link {
        font-size: 15px !important;
        padding: 10px;
        border: unset;
        text-transform: uppercase;
        background-color: unset;
    }

    .brand-card-outer-wrapper .col-sm-6 {
        width: 50%;
        margin-bottom: 20px;
    }

    .brand-card-outer-wrapper {
        padding: unset;
    }
}