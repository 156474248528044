.pagination-wrapper .page-link {
    font-size: 22px;
    background: unset;
    border: unset;
    outline: unset;
    color: unset;
}

.pagination-wrapper .page-item {
    font-family: "source-sans-pro";
    color: #C5C5C5;
    font-style: normal;
    font-weight: 700;
    margin: 0 4px;
    cursor: pointer;
}

.pagination-wrapper .page-item a {
    box-shadow: unset;
    outline: unset;
    border: unset;
}

.pagination-wrapper .page-item.active {
    color: #454545;
}

.pagination-wrapper .page-item:first-child .page-link span ,.pagination-wrapper .page-item:last-child .page-link span {
    display: none;
}

.pagination-wrapper .page-item:first-child .page-link {
    background: no-repeat url('../../assets/images/arrowLeft.png');
    width: 50px;
    height: 50px;
    background-size: contain;
}

.pagination-wrapper .page-item:last-child .page-link {
    background: no-repeat url('../../assets/images/arrowRight.png');
    width: 50px;
    height: 50px;
    background-size: contain;
}