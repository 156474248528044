.wishlist-h1 {
    color: #000;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 30px;
}

.wishlist-h span {
    color: #454545;
font-family: Brown;
font-size: 18px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.wishlist-h select { 
    width: unset;
}

.wishlist-h .sortDropdown span { 
    width: unset;
}

.product-wrapper .product-card {
    width: 100% !important;
}