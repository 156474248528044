.forgot-left {
    background-color: #fff !important;
}

.forgot-left img {
    height: auto !important;
}

.forgot-page-outer form label {
    color: #828282;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
}


.forgot-page-outer h2 {
    color: #000;
    font-family: "Playfair Display";
    font-size: 42px;
    font-style: normal;
    font-weight: 700;
}

.forgot-txt {
    color: #828282;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}

.forgot-txt a {
    color: #BE8826;
    margin-left: 5px;
}

.forgot-page-outer form input {
    border-radius: 5px;
    border: 1px solid #DED2D9;
    height: 45px;
    color: #E0E0E0;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

@media screen and (max-width: 575px) {
    .forgot-page-outer .col-sm-12.col-md-12 { 
        width: 100%;
    }
    .forgot-page-outer .sign-up-left { 
        display: none !important;
    }
}