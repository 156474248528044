.scroll-to-top {
    position: fixed;
    z-index: 999;
    bottom: 20px;
    left: 20px;
    background-image: url('../../assets/images/scrolltotop.png');
    background-size: contain;
    background-repeat: no-repeat;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .scroll-to-top.visible {
    opacity: 1;
  }
  