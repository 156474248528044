.product-banner h1 {
    color: #FFF;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    margin-top: -20px;
}

.product-banner h1 span {
    border-bottom: 1px solid #fff;
}

.brands-tabs-outer ul {
    margin-top: -65px !important;
}

/* .brands-tabs-outer .tab-content {
    margin-top: 50px;
} */

.brand-img-wrapper {
    position: relative;
    width: 100%;
    height: auto;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 20px;
}

.brand-img-wrapper .brand-img {
    transition: all 0.4s ease-in-out;
}

.brand-img-wrapper:hover .brand-img {
    transform: scale(1.5);
    /* overflow: hidden; */
    /* transition: all 0.4s linear; */
    /* box-shadow: inset -6px -1px 32px 0px rgba(0, 0, 0, 0.75); */
    /* transition: all 0.3s ease-in-out; */
}

.brand-img-wrapper img {
    width: 100%;
    max-width: 100%;
    /* object-fit: cover; */
    /* height: 350px; */
}

.brand-img-wrapper::before {
    content: "";
    background: rgba(0, 0, 0, 0.38);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
}

.brands-tabs-outer .div-nav-tab.nav-tabs .nav-link {
    color: #C6C6C6;
    text-align: center;
    font-family: "Brown";
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: uppercase;
}

.brands-tabs-outer .div-nav-tab.nav-tabs .nav-link.active {
    color: #fff;
}

.all-brands-outer { 
    margin: 0 50px;
}

.all-brands-outer img{
    max-width: 100%;
    /* object-fit: cover; */
}

.brand-img-wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand-img-wrapper .brand-logo {
    position: absolute;
    width: 180px;
    z-index: 1;
}

.brands-tabs-outer .nav-tabs.div-nav-tab .nav-link.active::after {
    background-color: #fff !important;
}


@media screen and (max-width: 991px) {
    .product-banner h1 {
        font-size: 40px;
    }
    .brand-img-wrapper { 
        /* width: 50%; */
    }
}

@media screen and (max-width: 768px) {
    .brand-img-wrapper img {
        min-height: 250px;
        height: 250px;
    }
}


@media screen and (max-width: 585px) {
    .product-banner h1 {
        font-size: 26px;
    }
    .brand-img-wrapper { 
        width: 100%;
    }
}