.offer-modal .modal-content {
    border-radius: 120px 0;
}

.offer-modal .modal-body {
    padding: unset;
}

.offer-modal .offer-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 120px;
}

.offerModalOuter {
    display: flex;
    flex-wrap: wrap;
}

.offer-logo img {
    max-width: 100%;
    width: 120px;
    height: auto;
}

.offer-right-text h3 {
    color: #454545;
    text-align: center;
    font-family: " Playfair Display";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}

.offer-right-text input {
    width: 100%;
    border-radius: 7px;
    background: #F6F3EE;
    font-size: 14px;
    height: 45px;
}

.offer-form {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.decline-btn {
    background: unset !important;
    outline: unset !important;
    box-shadow: unset !important;
    border: unset;
    color: #454545 !important;
    font-family: "Brown";
    font-size: 17px;
    font-style: normal;
    font-weight: 700;
    line-height: 25px;
    text-decoration-line: underline;
    text-transform: uppercase;
}

.offer-right-text h1 {
    color: #000;
    font-family: "Brown";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: center;
}

.offer_submit_modal {
    padding: 30px 0;
}

.offer_submit_modal h2 i {
    color: #20AE5C;
    font-size: 40px;
}

.offer_submit_modal h2 {
    color: #20AE5C;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
}

@media screen and (max-width: 991px) {
    .offer-modal .modal-content {
        border-radius: 0;
    }
    .offer-modal .offer-image {
        border-radius: 0 !important;
        max-height: 300px;
    }
    .offer-right-text h3 {
        font-size: 18px;
    }
    .offer-right-text h1 {
        font-size: 34px;
    }
}

@media screen and (max-width: 767px) {
    .offer-form {
        flex-wrap: nowrap;
        margin: 15px 0 !important;
    }

    .offer-text2 {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .offer-text2 p {
        margin: 0 !important;
    }

    .offer-logo img {
        max-width: 100%;
        width: 110px;
        height: auto;
    }

    .offer-right-text {
        padding: 10px !important;
        margin-bottom: 10px;
    }

    .offer-modal .modal-content {
        border-radius: 0;
    }

    .offerModalOuter .ofr-l {
        width: 100%;
    }

    .offerModalOuter .ofr-r {
        width: 100%;
    }

    .offer-modal .content-btn {
        min-width: 100px;
        height: 42px;
        border-radius: 2px 10px;
        font-size: 12px;
        margin-left: 10px !important;
        box-shadow: unset !important;
    }

    .offer-right-text h1 {

        font-size: 30px;
    }

    .offer-right-text h3 {
        font-size: 16px;
        margin-bottom: 7px;
    }

    .offer-modal .offer-image {
        max-height: 250px;
    }

    .offer-right-text input {
        width: 75%;
        border-radius: 7px;
        background: #F6F3EE;
        font-size: 14px;
        height: 45px;
        margin: unset !important;
    }

    
    .offer-modal .content-btn {
        font-size: 12px !important;
    }
}

@media screen and (max-width: 480px) {

    .offer-right-text h1 {
        font-size: 24px;
    }

    .offer-right-text input {
        height: 40px;
        width: 65%;
    }

    .offer-modal .content-btn {
        font-size: 12px !important;
        height: 38px;
    }

    .offer-logo  {
        margin-top: 10px !important;
    }

    .offer-logo img {
        width: 95px;
    }
}