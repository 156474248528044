.collection-wrapper h3 {
    color: #000;
    font-family: Playfair Display,serif;
    font-size: 34px;
    font-weight: 700;
}

.collection-wrapper img {
    height: 300px;
    width: 100%;
    max-width: 100%;
    height: 100%;
}

.collection-wrapper .col-8 {
    width: 70.8%;
}

.collection-wrapper .col-4 {
    width: 29.2%;
}

.collection-wrapper .col-61 {
    width: 64.6%;
}

.collection-wrapper .col-3 {
    width: 35.4%;
}



.collection-card {
    position: relative;
    overflow: hidden;
}

.collection-card-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.70);
    z-index: 9;
}

.collection-card-bottom span {
    color: #FFF;
    text-align: center;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    margin-bottom: 3px;
}

.collection-card-bottom i {
    font-size: 20px;
    color: #fff;
    transition: transform 0.3s ease-out;
}

.collection-card-bottom:hover i {
    transform: translateX(5px);
    transition: .4 ease;
}


.collection-card:hover .collection-card-bottom  {
    transform: translate(0%, 0%);
    transition: all 0.5s ease;
} 

.collection-card .card-top {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.collection-card .collection-card-bottom {
    width: 100%;
    position: absolute;
    z-index: 20;
    bottom: 0;
    color: #ffffff;
    transform: translate(0%, 100%);
}

@media screen and (max-width: 1200px) {
    .collection-wrapper h3 { 
        font-size: 26px;
    }
    .collection-wrapper-text p { 
        font-size: 14px;
    }
    .collection-card-bottom {
        height: 65px;
    }
    .collection-card-bottom i {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .collection-wrapper h3 {
        font-size: 22px;
        text-align: center;
    } 
    .collection-wrapper p {
        text-align: center;
        font-size: 13px;
        padding-bottom: unset !important;
    }
    .collection-wrapper-text button {
        margin: auto;
    }
    .collection-wrapper img {
        height: 200px;
        object-fit: cover !important;
    }
    .collection-wrapper .col-4 {
        width: 100%;
    }
    .collection-wrapper .col-3 
    {
        width: 100%;
    }
    .collection-wrapper .col-61 {
        width: 100%;
    }
    .collection-wrapper-btm { 
        flex-direction: column-reverse;
    }
    .collection-wrapper .col-8 {
        width: 100%;
    }
    .collection-card-bottom {
        height: 55px;
    }
    .collection-card-bottom span {
        font-size: 13px;
    }
  
    .collection-wrapper-text button { 
        display: none;
    }
    .collection-card-bottom i { 
        font-size: 12px;
    }
}

@media screen and (max-width: 585px) {

    .collection-card-bottom  {
        transform: translate(0%, 0%) !important;
        transition: all 0.5s ease;
    } 
}