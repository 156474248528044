.testimonials h2 {
    color: #000000;
    font-family: 'Playfair Display', serif;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    text-align: center;
}


.testimonials .owl-carousel.owl-drag .owl-item {
    display: flex;
    align-items: center;
    justify-content: center;
    position: unset !important;
} 



.testimonials .item {
    height: auto;
    text-align: center;
    margin-bottom: 0px;
    transform: scale3d(0.8, 0.8, 1);
    transition: all 0.3s ease-in-out;
}

.testimonials .owl-carousel .owl-nav.disabled, .testimonials .owl-carousel .owl-dots.disabled { 
    display: block !important;
}

.testimonials .owl-item.active.center .item {
    opacity: 1;
    transform: scale3d(1.2, 1.2, 1.2);
}

.testimonials .owl-carousel .owl-nav button span {
    display: none;
}

.testimonials .owl-carousel .owl-nav button.owl-prev {
    background-image: url('../../../../assets/images/arrowLeft.png') !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-color: unset !important;
    box-shadow: 0px 0.7699999809265137px 9.210000038146973px 0px rgba(0, 0, 0, 0.03), 0px 3.6500000953674316px 16.31999969482422px 0px rgba(0, 0, 0, 0.03), 0px 6.519999980926514px 27.020000457763672px 0px rgba(0, 0, 0, 0.04), 0px 12.34000015258789px 32.869998931884766px 0px rgba(0, 0, 0, 0.04), 0px 19.780000686645508px 29.420000076293945px 0px rgba(0, 0, 0, 0.10), 0px 61px 49px 0px rgba(0, 0, 0, 0.13);    width: 50px;
    height: 50px;
    border-radius: 50%;
    position: absolute;
    top: 42%;
    left:-10px;
}

.testimonials .owl-carousel .owl-nav button.owl-next {
    background-image: url('../../../../assets/images/arrowRight.png') !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    border-radius: 50%;
    background-color: unset !important;
    width: 50px;
    box-shadow: 0px 0.7699999809265137px 9.210000038146973px 0px rgba(0, 0, 0, 0.03), 0px 3.6500000953674316px 16.31999969482422px 0px rgba(0, 0, 0, 0.03), 0px 6.519999980926514px 27.020000457763672px 0px rgba(0, 0, 0, 0.04), 0px 12.34000015258789px 32.869998931884766px 0px rgba(0, 0, 0, 0.04), 0px 19.780000686645508px 29.420000076293945px 0px rgba(0, 0, 0, 0.10), 0px 61px 49px 0px rgba(0, 0, 0, 0.13);    height: 50px;
    position: absolute;
    top: 42%;
    right: -10px;
}

.testimonials .owl-stage-outer { 
    padding: 70px 0 !important;
}

.testimonials .owl-theme .owl-dots .owl-dot span {
    background-color: #A7A7A7;
    transition: .4s ease;
    width: 12px;
    height: 12px;
}

.testimonials .owl-theme .owl-dots .owl-dot.active span,.testimonials .owl-theme .owl-dots .owl-dot:hover span {
    background-color: #454545;
    transition: .4s ease;
}

.testi-carousel::before, .testi-carousel::after {
    content: '';
    position: absolute;
    bottom: -70px;
    left: 0;
    background-image: url('../../../../assets/images/testibeforebg.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 200px;
    height: 200px;
}

.testi-carousel::after { 
    bottom: unset !important;
    right: 0;
    left: unset;
    top: -70px;
    z-index: -1;
}

@media screen and (max-width: 991px) {
    .testimonials h2 {
        font-size: 40px;
    }
}

@media only screen and (max-width: 767px) {
    .testimonials h2 {
        font-size: 21px;
    }
    .testi-head { 
        padding-bottom: 20px !important;
    }

    .testimonials .owl-theme .owl-dots { 
        display: none;
    }
    .testimonials .owl-carousel .owl-nav button.owl-prev {
        top: unset;
        left: 60px;
        bottom: -30px;
    }
    .testimonials .owl-carousel .owl-nav button.owl-next {
        top: unset;
        right: 60px;
        bottom: -30px;
    }
}