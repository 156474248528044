
.category-swiper .slick-track {
    padding-top: 100px;
}

.center-slider{ 
    overflow: hidden;
}

.category-swiper .slick-prev {
    background-image: url('../../../../assets/images/arrowLeft.png') !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-color: unset !important;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 9;
    top: 60%;
    left: 10%;
    transition: .4s ease;
}

.category-swiper .slick-next{
    background-image: url('../../../../assets/images/arrowRight.png') !important;
    background-repeat: no-repeat !important;
    background-size: contain !important;
    background-color: unset !important;
    width: 50px;
    height: 50px;
    position: absolute;
    z-index: 9;
    top: 60%;
    right: 10%;
    transition: .4s ease;
}

.category-swiper .slick-next:hover , .category-swiper .slick-prev:hover{
    filter: brightness(1.5);
}

.category-swiper .slick-slide img { 
    width: 100px !important;
    transition: all .4s ease-in-out;
}


.category-center-bg-inner {
    position: absolute;
    top: -55px;
    bottom: 0;
    left: 30px;
    height: 120px;
    width: 830px;
    -webkit-transform: rotateZ(90deg);
    transform-origin: 0 100%;
    white-space: nowrap;
    background: #F6F3EE;
}

.category-swiper .slick-slide {
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: unset !important;
}

.category-swiper .slick-slide.slick-center.slick-current img{
    transition: all .4s ease-in-out;
    transform: scale3d(3, 3, 3) !important;
}

.carousel-heading {
    color: #454545;
    text-align: center;
    text-shadow: 0px 16px 23px rgba(0, 0, 0, 0.25);
    font-family: "Brown";
    font-size: 150px;
    font-style: normal;
    letter-spacing: 25px;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    position: absolute;
    top: 5px;
}


.category-swiper .slick-prev:before, .category-swiper .slick-next:before {
    display: none;
}

.center-slider::after {
    content: "";
    width: 100%;
    height: 120px;
    background: #F6F3EE;
    position: absolute;
    top: 52%;
    left: 0;
    z-index: -1;
} 

/* .category-wrapper .content-btn {
    height: 60px;
    font-size: 16px;
} */

.category-swiper .slick-dots { 
    display: none !important;
}

    /* .center-slider::before {
        content: "";
        width: 100%;
        height: 100px;
        background: #F6F3EE;
        position: absolute;
        left: 0;
        bottom: 0;
    } */
@media screen and (max-width: 1200px) {
    .category-swiper .slick-next { 
        right: 0;
    }

    .category-swiper .slick-prev { 
        left: 0;
    }
    
    .carousel-heading { 
        font-size: 120px;
    }
}

@media screen and (max-width: 990px) {
    .carousel-heading {
        font-size: 80px;
        text-shadow: 0px 16px 23px rgba(0, 0, 0, 0.15);
    }
    
}

@media only screen and (max-width: 767px) {
    .row-head h6 {
        font-size: 15px;
        width: 90%;
    }
    .category-center-bg-inner { 
        display: none;
    }
    .carousel-heading {
        font-size: 70px;
        line-height: normal;
        letter-spacing: 11.25px;
        top: 0;
    }
    .category-swiper .slick-next {
        top: 54%;
        right: 10%;
        bottom: 0;
    }
    .category-swiper .slick-prev {
        top: 54%;
        left: 10%;
        bottom: 0;
    }
    .category-swiper .slick-slide img {
        width: 55px !important;
    }
    .category-swiper .slick-slide {
        min-height: 250px;
    }
    .category-swiper .slick-track {
        padding-top: 50px;
        padding-bottom: 30px;
    }
    .center-slider::after {
        height: 75px;
        top: 45.5%;
    }
    .center-slider { 
        padding-top: 0 !important;
    }
    .category-swiper .slick-slide.slick-center.slick-current img{
        transition: all .4s ease-in-out;
        transform: scale3d(4, 4, 4) !important;
    }
    /* .category-wrapper .content-btn {
        font-size: 16px;
        max-width: 200px !important;
    } */
    
}

@media screen and (max-width: 480px) {
    .category-swiper .slick-next { 
        top: unset;
    }
    .category-swiper .slick-prev { 
        top: unset;
    }
    .carousel-heading { 
        font-size: 40px;
    }
}