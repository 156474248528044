.filter-input-wrapper {
    color: #454545;
    font-family: 'Brown';
    font-style: normal;
    font-weight: 400;
    position: relative;
}

.filter-input-wrapper .form-label {
    margin-bottom: 0px;
    color: #454545;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
}

.filter-input-wrapper .form-check label {
    color: #454545;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-transform: capitalize;
}

.filterBtn1 {
    color: #fff;
    width: unset;
    text-align: center;
    font-family: Brown;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding: 7px 10px;
    background: #454545;
    border-radius: 5px;
    display: flex;
    align-items: center;
    border: none;
    margin-left: 15px;
    transition: all .4s ease-in-out;
}

.filterBtn1:hover {
    background: #000;
    transition: all .4s ease-in-out;
}

/* .accordion-body input[type="radio"] {
    appearance: none;
    background-color: #fff;
    margin: 0;
    font: inherit;
    color: currentColor;
    width: 1.15em;
    height: 1.15em;
    border: 0.15em solid currentColor;
    border-radius: 50%;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
  }

  .accordion-body input[type="radio"]::before {
    content: "";
    width: 0.65em;
    height: 0.65em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em grey;
    background-color: grey;
  }
  
  .accordion-body input[type="radio"]:checked::before {
    transform: scale(1);
  } */
/*  .accordion-body   input[type="radio"]:checked::before {
    transform: scale(1);
  } */

.filter-input-wrapper .accordion-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    align-items: center;
    padding: 4px 0;
}

.filter-input-wrapper .accordion-header i {
    font-size: 12px;
}

/* The sidebar menu */
.sidebar {
    height: 100%;
    /* position: absolute; */
    z-index: 1;
    /* top: 0; */
    left: 10px;
    padding-top: 10px;
    overflow-x: hidden;
    transition: 0.5s;
    background: #FFF;
    box-shadow: 10px 14px 33px 0px rgba(142, 142, 142, 0.08);


    display: block;
    position: sticky;
    width: 25.75rem;
    top: 155px;
    height: calc(100vh - 128px);

    transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
    transform: translate3d(-160px, 0, 0);
}

/* The sidebar links */
.sidebar a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
}

/* When you mouse over the navigation links, change their color */
.sidebar a:hover {
    color: #f1f1f1;
}

/* Position and style the close button (top right corner) */
.sidebar .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

/* The button used to open the sidebar */
.openbtn {
    font-size: 20px;
    cursor: pointer;
    background-color: #111;
    color: white;
    padding: 10px 15px;
    border: none;
}

.openbtn:hover {
    background-color: #444;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
#main {
    transition: margin-left .5s;
    /* If you want a transition effect */
    /* padding: 20px; */
}

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidebar {
        padding-top: 20px;
    }

    .sidebar a {
        font-size: 18px;
    }
}

.sidebar-head h2 {
    font-family: "Brown";
    color: #454545;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
}

.acc-body-span {
    color: #7F7F7F;
    font-family: Brown;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}


.sidebar-head h2 i {
    margin-top: 5px;
    font-size: 18px;
}

.sidebar-head button i {
    color: #C7C7C7;
    font-size: 20px;
}

.filter-input-wrapper .accordion-item {
    border: unset !important;
}

.filter-input-wrapper .accordion-item button {
    border: unset !important;
    outline: unset !important;
    background: unset !important;
    box-shadow: unset !important;
    padding: 0;
}

.filter-input-wrapper .accordion-item button:target,
.filter-input-wrapper .accordion-item button:focus {
    border: unset !important;
    outline: unset !important;
    box-shadow: unset !important;
}


.filter-input-wrapper .accordion-button::after {
    transform: rotate(0);
    margin: unset;
    width: 9px;
    height: 11px;
    background-size: contain;
    margin-left: 5px;
    background-image: url('../../assets/images/accordianicon.png');
}

.filter-input-wrapper .accordion-body {
    padding: 10px 0px 10px !important;
}


.filter-input-wrapper .accordion-button:not(.collapsed)::after {
    transform: rotate(90deg);
}


.mul-checkbox-input label {
    color: #454545;
    font-family: "Brown";
    font-size: 16px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    text-transform: capitalize;
}

.filter-input-wrapper .form-check {
    margin-bottom: 0;
    padding-bottom: 3px;
    position: relative;
}

.is-sticky {
    position: sticky;
    top: 0;
    z-index: 999;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.displayNone {
    display: none;
}

.displayBlock {
    /* display: block; */
    transform: translate3d(0, 0, 0);
}

/* [type="radio"] input:checked + label:before {
    border-radius: 50%;
    border: 2px solid #26a69a;
}

[type="radio"] input:checked + label:after {
    border-radius: 50%;
    border: 2px solid #26a69a;
    background-color: #26a69a;
    z-index: 0;
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
}

[type="radio"]+label:before, [type="radio"]+label:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 16px;
    height: 16px;
    z-index: 0;
    transition: .28s ease;
}

[type="radio"]:not(:checked), [type="radio"]:checked {
    position: absolute;
    left: -9999px;
    visibility: hidden;
} */


@media screen and (max-width:580px) {
    .displayBlock {
        /* position: absolute;
        width: 18.75rem;
        top: 5px;
        height: 100%; */
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999999;
        width: 85%;
        color: #fff;
        height: 100%;
        padding: 1em 20px 20px 20px;
        background-color: #fff;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
        transform: translate(0, 0, 0);
    }
}