
.brand-card-wrapper img {
    transition: all 0.4s ease-in-out;
}

.brand-card-wrapper {
    position: relative;
    width: 215px;
    height: 315px;
    overflow: hidden;
    transition: all 0.6s ease-in-out;
    box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.25);
    margin-bottom: 15px;
}

.brand-card-wrapper:hover img {
    /* filter: blur(1.4px); */
    transform: scale(1.5);
    overflow: hidden;
    transition: all 0.4s linear;
    box-shadow: inset -6px -1px 32px 0px rgba(0, 0, 0, 0.75);
    transition: all 0.3s ease-in-out;
}


.brand-card-wrapper:hover .card-bottom {
    transform: translate(0%, 0%);
    transition: all 0.8s ease;
} 

.brand-card-wrapper .card-top {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
}

.brand-card-wrapper .card-bottom {
    width: 100%;
    position: absolute;
    z-index: 20;
    bottom: 0;
    color: #ffffff;
    transform: translate(0%, 100%);
}

@media only screen and ( max-width: 991px ) {
    .brand-card-wrapper {
        width: 100%;
    }
    .brand-card-wrapper .card-bottom {
        transform: translate(0%, 0%) !important;
    }
    .brand-card-wrapper:hover img {
        transform: unset !important;
    }
}

@media only screen and ( max-width: 585px ) {
    .brand-card-wrapper {
       height: 285px;
    }
    
}


@media only screen and ( max-width: 440px ) {
    .brand-card-wrapper {
       height: 230px;
    }
    
}