.review-card {
    width: 350px;
    min-width: 350px;
    min-height: 340px;
    max-height: 400px;
    border: unset;
    outline: unset;
    position: relative;
    /* background-image: url('../../assets/images/reviewbg.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
    background-color: unset !important;
    filter: drop-shadow(0px 6px 6px rgba(0, 0, 0, 0.10));
}

.testimonials .owl-carousel.owl-drag .owl-item .review-card{
    background-image: url('../../assets/images/reviewbg.png');
}

.testimonials .owl-carousel.owl-drag .owl-item.active.center .review-card {
    background-image: url('../../assets/images/reviewbg1.png');
} 


.review-card .profile-img {
    max-width: 100% !important;
    width: 80px !important;
    height: auto !important;
    margin-top: -40px !important;
    display: unset !important;
}

.review-card .d-comma img {
    display: unset !important;
    width: unset !important;
}

.review-card .card-title {
    color: #454545;
    font-family: "Brown";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
}

.review-card .card-text {
    color: #454545;
    font-family: "source-sans-pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.review-date {
    position: absolute;
    bottom: 20px;
    width: 100%;
    z-index: 0;
    left: 0;
    display: flex;
    justify-content: center;
    color: #BCBCBC;
    text-align: center;
    font-family: "source-sans-pro";
    font-size: 11px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.google-post {
    position: absolute;
    bottom: 20px;
    left: 30px;
}

.google-post img {
    width: 30px !important;
    height: 30px;
}

.google-post p {
    text-align: left;
    margin-left: 5px;
    color: #454545;
    font-family: "source-sans-pro";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.rating-star {
    font-size: 11px;
    margin-bottom: 5px;
    color: #f5f5f5;
}

.rating-star .fa-star.gold-star {
    color: #F4B924;
}

.rating-star .fa-star.grey-star { 
    color: #f5f5f5;
}

@media only screen and (max-width: 580px) {
    .review-card {
        width: 239px;
        min-width: 310px;
        min-height: 300px;
        max-height: 400px;
    }
    .review-card .card-text {
     
        font-size: 11px;
    }
}