.product-detail-ad {
    border-radius: 5px;
    background: #454545;
    max-width: 700px;
    height: 70px;
    display: flex;
    align-items: center;
    margin: auto;
    justify-content: space-between;
}

.error_messages {
    color: red !important;
}

.error_messages {
    color: red;
}

.product-detail-ad img {
    max-width: 100%;
    margin-right: 7px;
    width: 35px;
    height: 30px;
}

.product-detail-ad span {
    list-style-type: none;
    color: #FFF;
    font-family: 'brown';
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    display: flex;
    align-items: center;
}

.product-info {
    font-style: normal;
    font-weight: 400;
}

.product-info img {
    width: 120px;
}

.product-info h3 {
    color: #000;
    font-family: "Brown";
    font-size: 26px;
    text-transform: capitalize;
}

.product-info h1 {
    font-family: 'source-sans-pro';
}

.product-info h6 {
    color: #000;
    font-family: 'source-sans-pro';
    font-size: 20px;
}

.product-info label {
    font-family: 'source-sans-pro';
    font-size: 17px;
    margin: 0;
    padding-top: 5px;
}

.product-info .p-select {
    height: 40px;
    color: #454545;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    border-radius: 7px;
    background: #F6F3EE;
    -webkit-appearance: none;
    -moz-appearance: none;
    background-image: url('../../assets/images/arrowDown.png');
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 16px;
}

.product-info .p-select1 {
    max-width: 70px;
}

.product-info .p-select2 {
    max-width: 190px;
    min-width: 150px
}

.product-info .p-select3 {
    max-width: 150px;
}

.product-info .size-info {
    color: #BBB;
    text-align: center;
    font-family: "source-sans-pro";
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    margin-top: 5px;
}

.product-info .content-btn {
    /* width: 300px; */
    height: 55px;
    font-size: 14px;
}

.product-info .pro-btn2 {
    color: #454545;
    background: #FFF;
    box-shadow: 0px 0.7699999809265137px 9.210000038146973px 0px rgba(0, 0, 0, 0.03), 0px 3.6500000953674316px 16.31999969482422px 0px rgba(0, 0, 0, 0.03), 0px 6.519999980926514px 27.020000457763672px 0px rgba(0, 0, 0, 0.04), 0px 12.34000015258789px 32.869998931884766px 0px rgba(0, 0, 0, 0.04), 0px 19.780000686645508px 29.420000076293945px 0px rgba(0, 0, 0, 0.10), 0px 61px 49px 0px rgba(0, 0, 0, 0.13);
}

.product-info .discount-text {
    color: #0D4DF4;
    text-align: center;
    font-family: "source-sans-pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.product-description-wrapper {
    background: #F6F3EE;
}

.product-description-wrapper h2 {
    color: #000;
    font-family: 'Playfair Display', serif;
    font-size: 50px;
}

.product-description-wrapper p {
    color: #5C5C5C;
    text-align: center;
    font-family: "source-sans-pro";
    font-size: 16px;
}

.pd-wrapper-inner {
    width: 80%;
    margin: auto;
}

.pd-info span {
    color: #5C5C5C;
    font-family: "source-sans-pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
}

.pd-info2 label {
    display: block;
    width: 100%;
    color: #5C5C5C;
    font-family: "source-sans-pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
}

.pd-info label {
    display: block;
    width: 100%;
    color: #000;
    font-family: "source-sans-pro";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    text-transform: uppercase;
}

.product-contact-us-wrapper .contact-us-form {
    box-shadow: 0px 8px 39px 0px rgba(0, 0, 0, 0.08);
}

.product-contact-us-wrapper .pc-h1 {
    color: #454545;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 45px;
    font-style: normal;
    font-weight: 400;
}

.product-info {
    font-weight: 600;
  }
  
  .product-info h3 {
    color: #333;
    font-size: 28px;
  }
  
  .product-info h6 {
    color: #555;
    font-size: 18px;
  }
  
  .product-info label {
    font-size: 16px;
  }
  
  .product-info .content-btn {
    /* width: 250px;
    height: 50px;
    font-size: 16px; */
  }
  
  .product-info .pro-btn2 {
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
  }
  
  .product-description-wrapper h2 {
    color: #333;
    font-size: 36px;
  }
  
  .product-description-wrapper p {
    color: #666;
    font-size: 16px;
  }
  
  .pd-info span {
    color: #666;
    font-weight: 600;
  }
  
  .pd-info label {
    color: #333;
    font-weight: 600;
  }

  .button-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Responsive columns with a minimum width */
    grid-gap: 10px; /* Add some gap between buttons for spacing */
  }

  .button-container .content-btn {
    font-size: 13px;
    border-radius: 2px 25px;
    color: #fff;
    box-shadow: 0px 0.77px 9.21px 0px rgba(0, 0, 0, 0.03), 0px 3.65px 16.32px 0px rgba(0, 0, 0, 0.03), 0px 6.52px 27.02px 0px rgba(0, 0, 0, 0.04), 0px 12.34px 32.87px 0px rgba(0, 0, 0, 0.04), 0px 19.78px 29.42px 0px rgba(0, 0, 0, 0.1), 0px 20px 49px 0px rgba(0, 0, 0, 0.13);
    border: unset;
    min-width: 250px;
    max-width: 315px;
    height: 50px;
    display: flex;
    align-items: center;
    font-family: "Brown";
    justify-content: center;
    text-transform: uppercase;
    transition: all .4s ease-in-out;
}

.button-container .pro-btn1 {
    background: #454545;
}

.button-container .pro-btn1:hover {
    background: #000;
}

.button-container .pro-btn2 {
    background: #fff;
    color: #454545;
}

.button-container .pro-btn2:hover {
    background: #000000;
    color: #fff;
}

@media only screen and (max-width: 991px) {
    .button-container   .content-btn {
        font-size: 12px; /* Adjust font size for tablets */
    }
}

@media screen and (max-width: 767px) {
    .button-container  .content-btn {
        min-width: 100%; /* Full width on smaller screens */
        max-width: none; /* No maximum width on smaller screens */
    }
    .p-outer {
        margin: 10px 0 !important;
        padding: 20px 0 !important;
    }
    .product-detail-ad span {
        font-size: 12px;
    }
    .product-detail-ad {
        display: none;
    }
    .product-col-2 {
        margin-top: 40px;
    }
    .button-container { 
        grid-template-columns: none;
    }
    
}