.filterBtn {
  background-color: #454545;
  border: unset;
  width: 150px;
  height: 45px;
  text-transform: uppercase;
  transition:  .4 ease-in-out;
}

.filterBtn:hover , .filterBtn:focus-visible, .filterBtn:focus, .filterBtn:active{
  background-color: #000 !important;
}

.phone-filter-btn-wrapper {
  display: none;
}

.product-count{
  color: #454545;
text-align: right;
font-family: Brown;
font-size: 17px;
font-style: normal;
margin-right: 10px;
}

.filterBtn i {
  margin-left: 5px;
}

.filterTop {
  font-family: "Brown";
  font-size: 17px;
}

.sortDropdown {
  display: flex;
  align-items: center;
}

.sortDropdown .sortLabel {
  white-space: nowrap; 
  /* overflow: hidden;  */
  text-overflow: ellipsis; 
  color: #454545;
  margin-right: 10px;
}

.sortDropdown button {
  border-radius: 7px;
  border: unset;
  color: #454545;
  background: #F6F3EE;
  text-transform: uppercase;
  height: 45px;
  max-width: 200px;
  flex-grow: 1; /* Allow the button to grow and fill the available space */
}

.product-wrapper-outer {
  /* display: flex; */
  transition: all 0.3s ease;
}

.product-wrapper-outer .product-card {
  width: 100%;
}



#main {
  flex: 1 1 auto;
  transition: all 0.3s ease-in-out;
}

.all_pr_wrapper {
  padding-left: 3rem;
}

.selected-filter-outer {
  border-radius: 7px;
  background: #F6F3EE;
  width: 48%;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 10px;
  width: 80%;
  margin: 0 15px;
}

.selected-filter-outer .selected-filter {
  color: #454545;
  font-family: "Brown";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
  border-radius: 2px;
  background: #FFF;
  padding: 3px 0 3px 3px;
  margin-right: 7px;
  cursor: default;
}

.selected-filter-outer .remove-filter {
  border-radius: 2px;
  background: #454545;
  color: #fff;
  padding: 3px 6px;
  margin-left: 7px;
}

.pro.bg-overlay-sidebar-open {
  display: none;
}

@media screen and (max-width: 767px) {

  .selected-filter-outer { 
    display: none;
  }
  .all_pr_wrapper {
    padding-left: unset;
  }

  .sortDropdown {
    flex-direction: column;
    align-items: flex-start;
  }

  .sortDropdown .sortLabel {
    margin-bottom: 5px;
  }

  .sortDropdown button {
    max-width: none;
    width: 100%;
  }

  .product-wrapper-outer .product-card .card-body {
    padding: 10px;
  }
  .product-wrapper-outer .product-card {
    width: 100%;
    min-height: 300px;
}
}

@media screen and (max-width: 575px) {
  .phone-filter-btn-wrapper {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: unset;
    z-index: 90;
  }
  .phone-filter-btn-wrapper button {
    width: 50%;
    border-radius: unset;
    background: #BE8826 !important;
    outline: unset !important;
    color: #fff !important;
    /* border: 1px solid; */
    /* border-color: #000 !important; */
    height: 45px;
    border: unset !important;
}
.phone-filter-btn-wrapper .dropdown-toggle {
  border-left: 1px solid #fff !important;
}
  .phone-filter-btn-wrapper .dropup.btn-group{
    width: 50%;
  }
  .selected-filter-outer {
    display: block !important;
    width: 100% !important;
    margin: 0 !important;
  }
  .phone-filter-btn-wrapper .dropup.btn-group  button {
    /* border-left: unset !important; */
  }
  .sortDropdown {
    display: none !important;
  }
  .filterBtn {
    display: none !important;
  }

  .pro.bg-overlay-sidebar-open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0;
    transition: opacity .15s linear;
    display: none;
  }
  
  .pro.bg-overlay-sidebar-open.active {
    opacity: 0.5;
    z-index: 1071;
    display: block;
  }

  .product-wrapper-outer .col-6 {
    padding: 0 10px;
  }

  .product-wrapper-outer {
    padding: 0 10px;
  }

}
