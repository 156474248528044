.product-banner {
    display: flex;
    align-items: center;
    /* background-image: url('../../assets/images/banner.png'); */
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 225px;
    position: relative;
}

.product-banner .container-fluid {
    justify-content: center;
    border-bottom: unset;
}

.product-banner .nav-link {
    border: unset;
    background-color: unset;
    font-size: 54px;
    margin: 0 15px;
    font-family: 'Playfair Display', serif;
    color: #B6B6B6;
}

.product-banner .nav-link.active {
    color: #fff;
}

.goldsmith-special {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 45px;
    width: 100%;
    border-radius: 2px;
    background: #BB8D3A;
    color: #FFF;
    text-align: center;
    font-family: "Brown";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
    position: absolute;
    left: 0;
    bottom: 0;
}

@media screen and (max-width: 991px) {
    .product-banner .nav-link {
        font-size: 40px;
    }

}

@media screen and (max-width: 767px) {
    .product-banner .nav-link {
        font-size: 36px;
    }
}

@media screen and (max-width: 590px) {
    .product-banner .nav-link {
        font-size: 26px;
    }
}


@media screen and (max-width: 480px) {
    .product-banner .container-fluid {
        justify-content: center;
        border-bottom: unset;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    .goldsmith-special {
        font-size: 15px;
        height: 35px;
    }
} 