* {
    margin: 0;
    padding: 0;
}

body {
    background: #fff;
}

.business-benefits {
    display: flex;
    width: 100%;
    margin: 0;
    background: #F6F3EE;
    color: #454545;
}

.benefit-card h3 {
    font-family: 'Playfair Display', serif;
    font-size: 18px;
    font-weight: 600;
    margin-top: 15px;
    color: #454545;
}

.benefit-card {
    width: 18%;
}

.business-benefits-inner {
    width: 90%;
}

.benefit-card p {
    font-family: "source-sans-pro";
    font-size: 12px;
}

.row-head h2 {
    font-family: 'Playfair Display', serif;
    color: #000;
    text-align: center;
    font-size: 38px;
    font-style: normal;
    font-weight: 700;
}

.row-head h6 {
    font-family: 'source-sans-pro';
    color: #454545;
    font-size: 16px;
    width: 60%;
    line-height: 25px;
}

.content-btn {
    font-size: 13px;
    border-radius: 2px 25px;
    background: #BE8826;
    box-shadow: 0px 0.7699999809265137px 9.210000038146973px 0px rgba(0, 0, 0, 0.03), 0px 3.6500000953674316px 16.31999969482422px 0px rgba(0, 0, 0, 0.03), 0px 6.519999980926514px 27.020000457763672px 0px rgba(0, 0, 0, 0.04), 0px 12.34000015258789px 32.869998931884766px 0px rgba(0, 0, 0, 0.04), 0px 19.780000686645508px 29.420000076293945px 0px rgba(0, 0, 0, 0.10), 0px 20px 49px 0px rgba(0, 0, 0, 0.13);
    border: unset;
    min-width: 250px;
    max-width: 315px;
    height: 50px;
    display: flex;
    align-items: center;
    font-family: "Brown";
    justify-content: center;
    text-transform: uppercase;
    transition: all .4s ease-in-out;
}

.content-btn:focus-visible {
    background-color: #BE8826 !important ;
    background: #BE8826 !important ;
    box-shadow: none !important;
    outline: none !important;
}

.content-btn.btn:active {
    background: #454545;
}

.content-btn:hover {
    background: #000;
}

.arrowUpRight i {
    transform: rotate(45deg);
}



@media only screen and (max-width: 767px) {
    .benefit-card {
        width: 100%;
        text-align: center;
        padding: 15px;
    }
    .benefit-card:nth-child(even){
        background: #fff;
    }
    .business-benefits-inner {
        width: 100%;
    }
    .business-benefits .container { 
        padding: unset !important;
    }
    .row-head h2 {
        font-size: 22px;
    }
    .business-benefit-wrapper { 
        padding: unset !important;
    }
}

@media screen and (max-width: 585px) {
    .content-btn {
        min-width: 220px;
        height: 45px;
        font-size: 15px !important;
        border-radius: 2px 20px;
    }
}