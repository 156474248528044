.wishlist-not-found h1 {
    color: #000;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    margin-bottom: 20px;
}

.wishlist-not-found h5 {
    color: #454545;
    text-align: center;
    font-family: "source-sans-pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}

.no-product-bg-grey-wishlist { 
    margin-bottom: 150px;
}

@media screen and (max-width: 768px)  {
    .wishlist-not-found h1 {
        font-size: 30px;
    }
    .wishlist-not-found h5 {
        font-size: 16px;
    }
}