.contact-us {
    position: relative;
    background: #F6F3EE;
    width: 100%;
    margin: 0;
}


.contact-us .contact-us-form {
    position: absolute;
    right: 0;
}


@media screen and (max-width: 1400px) {
    .contact-us .contact-us-img {
        min-height:680px;
    }
}

@media screen and (max-width: 991px) {
    .contact-us .contact-us-img {
        min-height:230px;
        /* height: 230px !important; */
        height: auto !important;
    }
    .contact-us .container {
        padding: unset;
    }
    .contact-us .col-lg-8.col-md-9.col-sm-12 {
        width: 100%;
    }
    .contact-us .contact-us-form {
        position: relative;
        right: 0;
        top: -55px;
    }
    .contact-us-form {
        /* max-width: 320px; */
        border-radius: 70px 0;
    }
    .contact-us-wrapper {
        padding-bottom: unset !important;
    /* margin-bottom: unset !important; */
    justify-content: center;
    }

    .contact-us-form {
        min-height: unset !important;
        padding-bottom: 20px;
    }
}

@media screen and (max-width: 767wpx) {
    .contact-us-form {
        min-height: unset !important;
    }
}


