.about-us-bg {
    background-image: url('../../assets/images/aboutusbg.png');
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 460px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
}

.about-us-bg::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: -1;
    background: rgba(0, 0, 0, 0.50);
    /* Use rgba for transparency */
}

.about-us-bg h2 {
    width: 100%;
    display: block;
    color: #FFF;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 60px;
    font-style: normal;
    font-weight: 700;
    position: relative;
}

.about-us-bg h2 span {
    border-bottom: 1px solid #fff;
}

.about-us-lft img {
    max-width: 80%;
}

.about-us-bio {
    display: flex;
    padding: 0 20px;
}

.about-us-member-card {
    padding: 0 20px;
}

.about-us-member-card img {
    max-width: 100%;
}

.about-us-member-card h4 {
    font-family: 'Playfair Display', serif;
    color: #000;
    margin: 15px 0;
    text-align: center;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
}

.about-us-member-card p {
    color: #454545;
    text-align: center;
    font-family: "source-sans-pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
}

.our-store h2 {
    color: #000;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
}

.about-us-wrapper h2 {
    position: unset !important;
}

.our-store-inner {
    display: flex;
    flex-wrap: wrap;
}

.our-store-inner-card {
    width: 33.3%;
}

.our-store-inner-card h4 {
    font-family: 'Playfair Display', serif;

    color: #BB8D3A;
    text-align: center;
    font-family: "Playfair Display";
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

.our-store-inner-card-info {
    padding: 0 20px;
    margin: 20px 0 40px 0;
}

.our-store-inner-card-info a {
    display: block;
    width: 100%;
    text-align: center;
    color: #000;
    text-align: center;
    font-family: "source-sans-pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    text-decoration: none;
}

.our-store-inner-card-info .category-text {
    color: #BB8D3A;
    text-align: center;
    font-family: "source-sans-pro";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    width: 100%;
}

.our-store-inner-card p {
    color: #000;
    text-align: center;
    font-family: "source-sans-pro";
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 5px;
    width: 80%;
    margin: auto;
}

.our-store-inner-card img {
    max-width: 100%;
    width: 100%;
    object-fit: cover;
}


@media screen and (max-width: 991px) {
    .about-us-wrapper {
        padding-top: 20px !important;
    }
    .about-us-rght-info {
        padding-top: 20px ;
    }
    .our-store-inner-card p {
        font-size: 16px;
        width: unset;
    }
    .our-store-inner-card-info a {
        font-size: 16px;
    }
    .our-store-inner-card h4 {
        font-size: 24px;
    }
}


@media screen and (max-width: 767px) {
    .our-store-inner-card {
        width: 50%;
    }
}

@media screen and (max-width: 585px) {
    .our-store-inner-card {
        width: 100%;
    }

    .about-us-bg h2 {
        font-size: 40px;
    }

    .about-us-lft img {
        max-width: 100%;
        margin-bottom: 15px;
    }
}