.container-rr {
    display: block;
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    font-size: 15px;
    -webkit-user-select: none;
    user-select: none;
    color: #454545;
}

/* Hide the browser's default checkbox */
.container-rr input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border: 1px solid transparent
}

.container-rr input:checked~.checkmark {
    background-color: grey;

}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.container-rr input:checked~.checkmark:after {
    display: block;

}

.container-rr .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: 2px solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.forgot-l {

    color: #454545 !important;
    font-family: "Brown";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: unset !important;
    border: unset;
    text-decoration: underline;
}

.forgot-pass-div {
    display: none;
}

@media screen and (max-width: 480px) {
        .container-rr-outer {
            /* justify-content: center !important; */
        }
        
        .forgot-pass-div {
            display: block;
            text-align: center;
            margin-top: 15px;
        }

        .forgot-pass-div button {
            color: #BE8826 !important;

        }

        .login_btn {
            margin-top: 15px !important;
        }

        .container-rr-outer .forgot-l{
            display: none;
        }
}