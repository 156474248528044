.my-account-box {
    border-radius: 0px 120px;
    background: #F6F3EE;
    padding: 50px 30px;
    max-width: 70%;
    margin: 80px auto;
}


.signup-modal h2 {
    color: #000;
    font-family: 'Playfair Display', serif;
    font-size: 46px;
    font-style: normal;
    font-weight: 700;
}

.my-account-box .form-floating input {
    border-radius: 7px;
    border: 1px solid #DCDCDC;
    /* background: #F6F3EE !important; */
    height: 50px;
}

.my-account-box .form-floating input {
    height: 50px;
    min-height: 50px;
    padding: 5px .75rem;
}

.my-account-box .form-floating label {
    padding: 5px .75rem;
    display: flex;
    color: #B0B0B0;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    align-items: center;
}

.my-account-box .form-floating label::after {
    display: none
}

.register-btn {
    width: 250px;
}

.signup-text {
    display: flex;
    align-items: center;
    justify-content: center;
}

.PhoneInputCountry {
    border-radius: 7px 0px 0px 7px;
    background: #454545;
    margin-right: unset !important;
    width: 17% !important;
    justify-content: center !important;
    color: #fff;
}

.PhoneInputInput {
    border-radius: 0 7px 7px 0px !important;
    text-indent: 5px;
    font-family: "Brown";
    font-size: 14px;
    height: 50px;
    font-style: normal;
    font-weight: 400;
    border: unset !important;
    outline: unset !important;
}

.PhoneInputInput::placeholder {
    color: #B0B0B0 !important;

}


.my_account_outer h1 {
    color: #000;
    text-align: center;
    font-family: 'Playfair Display', serif;
    font-size: 70px;
    font-style: normal;
    font-weight: 700;
}



@media screen and (max-width: 991px) {
    .my-account-box {
        padding: 50px 30px;
        margin: 40px auto;
        max-width: 100%;
    }
    .PhoneInputCountry { 
        width: 20% !important;
        font-size: 14px;
    }
    .my_account_outer h1 {
        font-size: 50px;
    }
}

@media screen and (max-width: 480px) {
    .my-account-box-inner { 
        padding: 0 10px !important;
    }
    .my-account-box {
        border-radius: unset !important;
    }
}



