
.homeRightInfo h2 {
    font-size: 36px;
    font-family: 'Playfair Display', serif;
    color: #000;
    line-height: 40px;
    font-weight: 700;
}

.homeRightInfo h6 {
    color: #000;
    font-family: "Brown";
}

/* .homeRightInfo button {
    border-radius: 2px 18px;
    background: #BE8826;
    box-shadow: 0px 0.7699999809265137px 9.210000038146973px 0px rgba(0, 0, 0, 0.03), 0px 3.6500000953674316px 16.31999969482422px 0px rgba(0, 0, 0, 0.03), 0px 6.519999980926514px 27.020000457763672px 0px rgba(0, 0, 0, 0.04), 0px 12.34000015258789px 32.869998931884766px 0px rgba(0, 0, 0, 0.04), 0px 19.780000686645508px 29.420000076293945px 0px rgba(0, 0, 0, 0.10), 0px 61px 49px 0px rgba(0, 0, 0, 0.13);
    border: unset;
    width: 250px;
    color: #FFF;
    font-family: "Brown";
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    height: 45px;
} */

/* .homeRightInfo button:hover {
    transition: .4s ease;
    background: black;
    color: #fff;
} */


.homeRightInfo p {
    font-family: "source-sans-pro";
    font-size: 14px;
    text-transform: capitalize;
    color: #454545;
}

.homeRightInfo ul li {
    list-style-type: none;
    width: unset;
    border-radius: 50%;
    width: 45px;
    height: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #F6F3EE;
    margin: 0 10px;
    transition: .4s ease-in-out;
    box-shadow: 0px 2.767256498336792px 2.2138051986694336px 0px rgba(0, 0, 0, 0.02), 0px 6.650102138519287px 5.32008171081543px 0px rgba(0, 0, 0, 0.03), 0px 12.521552085876465px 10.017241477966309px 0px rgba(0, 0, 0, 0.04), 0px 22.3363094329834px 17.869047164916992px 0px rgba(0, 0, 0, 0.04), 0px 41.777610778808594px 33.422088623046875px 0px rgba(0, 0, 0, 0.05), 0px 100px 80px 0px rgba(0, 0, 0, 0.07);
}

.home-info-lft .carousel-control-next,.home-info-lft.carousel-control-prev {
    display: none;
}

.homeRightInfo ul li:hover {
    background: #000;
    color: #fff;
    transform: scale(1.1);
    transform: translateY(-5px);
    transition: .4s ease-in-out;
}

/* .homeRightInfo ul .fb-btn:hover {
    background: #3b5999;
    color: #fff;
    transition: .4s ease-in-out;
}

.homeRightInfo ul .insta-btn:hover {
    background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
    color: #fff;
    transition: .4s ease-in-out;
}

.homeRightInfo ul .wtsp-btn:hover {
    background: #25D366;
    color: #fff;
    transition: .4s ease-in-out;
}

.homeRightInfo ul .yt-btn:hover {
    background: #FF0000;
    color: #fff;
    transition: .4s ease-in-out;
} */

.homeimglft {
    border-top-left-radius: 250px;
    height: 540px;
    object-fit: cover;
}

.home-info-lft .carousel-indicators [data-bs-target] {
        width: 10px;
    height: 10px;
    border-radius: 50% !important;
    opacity: unset !important;
    margin: 0 3px;
}
.home-info-lft .carousel-indicators .active {
    width: 15px;
    height: 15px;
    margin-top: -3px;
}

.home-info-lft .carousel-indicators {
    margin-bottom:  unset !important;
}

.home-info-lft .carousel-control-prev, .home-info-lft .carousel-control-next{ 
    display: none !important;
}

.homeRightSocialIcons a {
    color: unset;
}

@media only screen and (max-width: 1200px) {
    .homeRightInfo h2 {
        font-size: 30px;
        margin-bottom: 10px !important;
    }
    .homeRightInfo p {
        font-size: 12px;
    }
}


@media screen and (max-width: 991px) {
    .homeimglft {
        border-top-left-radius: 0;
        height: 440px;
        object-fit: cover;
    }
}


@media only screen and (max-width: 767px) {
    .homeimglft {
        /* border-radius: 0 50px !important; */
        border-radius: unset !important;
        height: 220px;
        width: 100%;
        object-fit: cover;
    }
    .home-info-col {
        width: 100%;
        margin-top: 25px;
        margin-bottom: 10px;
    }
    .homeRightInfo h2 {
        font-size: 21px;
        line-height: 27px;
        width: 100% !important;
        position: absolute;
        top: 0;
    }
    .homeRightInfo h6 {
        font-family: "Brown";
        color: #454545;
        font-size: 14px;
    }
    .homeRightSocialIcons {
        display: none;
    }
    .homeRightInfo button {
        /* width: 200px; */
        font-size: 14px;
        /* height: 50px; */
    }
}   

@media screen and (max-width: 575px) {
    .homeRightInfo p {
        text-align: center;
    }
}