.header-ad {
    /* background: #F6F3EE; */
    background: #000;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    overflow: hidden;
    height: 45px;
}


.header-ad p {
    font-family: "Brown";
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    text-transform: uppercase;
    width: unset;
    margin: 0;
    position: absolute;
    opacity: 0;
    transition-property: opacity;
    transition-timing-function: ease-in-out;
}

/* Add this to your CSS file (style.css) */
.sticky-header {
    position: sticky;
    top: 0;
    background-color: #fff;
    /* Change to your desired background color */
    z-index: 99;
    transition: position 0.2s ease-in-out;
}

.header-icons img {
    max-width: 100%;
}


.header-icons .heart-imgg {
    max-width: unset;
    width: 23px;
    height: auto;
    margin: 0 0px;
    transition: .4s ease;
}

.header-nav-links-wrapper .nav-link {
    position: relative;
    display: flex;
    justify-content: center;
}

.header-nav-links-wrapper .nav-link img {
    position: absolute;
    bottom: -15px;
    display: flex;
    justify-content: center;
    width: 17px;
    height: 15px
}

.header-navbar {
    padding: 0 10px;
    box-shadow: 0px 16px 53px 0px rgba(0, 0, 0, 0.08);
}

.header-search input {
    border: 1px solid #DCDCDC;
    background-color: #F6F3EE;
    border-radius: 5px;
    text-indent: 10px;
    outline: unset;
    padding-right: 45px !important;
    color: #797979;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
}

.header .logo-img {
    width: 200px;
    height: auto;
}

.header .nav-link {
    text-transform: uppercase;
    margin: 0 7px;
    color: #454545;
    text-align: center;
    font-family: "Brown";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    transition: cubic-bezier(0.215, 0.610, 0.355, 1);
}

.header .nav-link.active {
    color: black;
    font-weight: 600;
}

.header .nav-link:hover {
    color: #000;
    transition: cubic-bezier(0.215, 0.610, 0.355, 1);
}

.navbar {
    background-color: #fff !important;
}

.header-icons .nav-link {
    margin-left: 10px !important;
    height: 80px !important;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}

.header-icons .btn {
    padding: 0px !important;
    font-size: 19px;
    background: unset !important;
}

.header-icons .btn::after {
    display: none;
}

.header-icons .dropdown-menu {
    position: absolute !important;
    top: 85%;
    left: -120px;
    color: #555;
    font-weight: 500;
    transition: 0.5s;
    z-index: 3;
}

.user-circle {
    border: 1px solid;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
}

.header-icons .menu .dropdown-item {
    background: unset;
    border: unset;
    outline: unset;
    transition: .4s ease;
    color: #555;
    margin: 6px 0;
}

.header-icons .menu .dropdown-item:hover {
    color: #000;
}

.header-search span {
    max-width: 750px;
}

.header-search span i {
    position: absolute;
    top: 11px;
    right: 30px;
    color: #454545;
}


.menu-wrap {
    display: none;
}

.menu-button {
    display: none;
}

button#open-button {
    display: none;
}

.bg-overlay-sidebar-open {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #000;
    opacity: 0;
    transition: opacity .15s linear;
    display: none;
    /* z-index: 1071; */
}

.bg-overlay-sidebar-open.active {
    opacity: 0.5;
    display: block;
    z-index: 10;
}

.getInTouchModal .contact-us-form {
    max-width: 100%;
}

.menu-wrappr-pro span { 
    color: #000;
    text-transform: capitalize;
    padding-bottom: 5px;
    font-size: 14px;
    border-bottom: 1px solid grey;
}

.menu-wrappr-pro p i{ 
    background: #f5f5f5;
    margin-right: 5px;
    padding: 10px;
    border-radius: 50%;
    color: #000;
}

.menu-wrap .accordion-header {
    color: #000;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #BCBCBC;
    padding-bottom: 5px;
    cursor: pointer;
    text-transform: capitalize;
}

.menu-wrap h6 { 
    color: #000;

}

.menu-wrap .accordion-body {
    display: flex;
    margin-top: 15px;
}
@media (min-width: 1200px) {

    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        max-width: 1300px;
    }
}

@media only screen and (max-width: 1200px) {
    .header .nav-link {
        font-size: 14px;
        margin: 0 -1px;
    }

    .header-icons .nav-link {
        margin-left: -20px;
    }

    .header .logo-img {
        width: 165px;
    }

    .header-navbar .container {
        max-width: 1080px;
    }
}


@media screen and (max-width: 991px) {
    .header-ad {
        padding: 10px 0 !important;
    }

    .header-ad span {
        font-size: 14px;
    }

    .header-navbar {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }

    .header-search {
        padding: 10px 0 20px 0 !important;
        margin-top: -8px;
    }

    .header .navbar-toggler {
        border: unset;
        position: absolute;
        left: 0;
        top: 15px;
        box-shadow: unset;
        outline: unset;

    }

    .header .logo-img {
        width: 125px;
        height: auto;
        margin-left: 40px;
    }

    .header .header-icons {
        flex-direction: row;
    }

    .header-icons .nav-link {
        text-align: right;
    }

    /* .map-pin {
        display: none;
    } */

    .header-icons img {
        max-width: 100% ;
    }

    .web-navbar-collapse {
        display: none;
    }


    .menu-wrap {
        display: block;
    }

    .menu-button {
        display: block;
    }

    button#open-button {
        display: block !important;
        position: absolute;
        top: 18px;
        left: 0;
        background: transparent;
        border: unset;
        z-index: 99;
        color: #fff;
        box-shadow: none;
    }

    .menu-wrap {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 999;
        width: 400px;
        color: #fff;
        height: 100%;
        padding: 1em 20px 20px 20px;
        background-color: #fff;
        box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
        transition: all 0.5s cubic-bezier(0.7, 0, 0.3, 1);
        transform: translate3d(-460px, 0, 0);
    }

  
    

    .menu-wrap.active {
        transform: translate3d(0px, 0, 0);
        overflow-y: auto;
    }

    .menu-wrap .menu .icon-list a {
        position: relative;
        display: block;
        padding: 5px 0;
        text-decoration: none;
        text-transform: capitalize;
        color: #454545;
        font-size: 14px;
        transition: all 0.3s ease;
    }
  

    .menu-wrap .menu .icon-list a:hover::before {
        width: 50%;
        background-color: #FFB800;
    }

    .loading {
        font-size: 14px;
        color: #000;
        display: block;
        width: 100%;
        text-align: center;
        margin: 7px 0;
    }

    /* Buttons */
    .close-button {
        right: 0;
        top: 10px;
        z-index: 100;
    }

    .menu-button{
        position: fixed;
        top: 50px;
        width: 50px;
        color: #fff;
        height: 50px;
        background-color: transparent;
        font-size: 20px;
        border: none;
        outline: none;
        cursor: pointer;
    }

    
 
    .close-button {
        color: #fff;
        height: 50px;
        background-color: transparent;
        font-size: 20px;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .menu-button {
        right: 0px;
        z-index: 102;
    }

    .close-button {
        right: 5px;
        z-index: 100;
        top: 10px;
    }

    .menu-wrap ul {
        margin: 0;
        padding: 0;
    }

    .close-button i {
        color: #000;
        /* border: 1px solid #000; */
        padding: 7px 9px;
        border-radius: 50%;
        font-size: 16px;
    }
    .header .nav-link {
        font-size: 18px;
        margin: 0 0 0 15px !important;
        height: 70px !important;
        
    }
}

@media screen and (max-width: 767px) {
    .menu-wrap {
        width: 300px;
        transform: translate3d(-360px, 0, 0);
    }
}

@media screen and (max-width: 585px) {
    /* .header-icons .heart-imgg {
        margin: 0 19px;
    } */
    .user-circle {
        width: 30px;
        height: 30px;
        font-size: 14px;
    }
    
    .header-ad p {
        font-size: 13px;
    }
    
}