.footer {
    background: #2B2B2B;
    color: #E7E7E7;
}

.footer ul {
    margin: 0;
    padding: 0;
}

.footer h4 {
    font-size: 16px;
    font-family: "Brown";
}

.footer ul li {
    font-family: "source-sans-pro";
    list-style-type: none;
    font-size: 15px;
    margin-bottom: 5px;
}

.footer ul li a {
    text-decoration: none;
    color: #fff;
}

.footer,
.footer span,
.footer p,
.footer h5 {
    font-family: "source-sans-pro";
    font-size: 15px;
}

.footer h5 {
    margin-bottom: 0;
}


.footer span a {
    color: #E7E7E7;
}

.footer .logo {
    width: 250px;
    height: auto;
}

.footer input {
    margin-right: 20px;
    height: 50px;
    width: 57%;
}

.footer h3 {
    font-family: 'Playfair Display', serif;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
}

.social-menu {
    display: flex;
    justify-content: center;
    align-items: center;
}

.social-menu ul {
    position: relative;
    padding: 0;
    margin: 0;
    display: flex;
    justify-content: center;
}

.social-menu ul li {
    list-style: none;
    margin: 0 15px;
}

.social-menu ul li .fab {
    font-size: 30px;
    line-height: 60px;
    transition: .3s;
    color: #000;
}

.social-menu ul li .fab:hover {
    color: #fff;
}

.social-menu ul li a {
    position: relative;
    display: block;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    text-align: center;
    transition: .6s;
    box-shadow: 0 5px 4px rgba(0, 0, 0, .5);
    display: flex;
    border: 2px solid transparent;
    outline: 2px solid transparent;
}


.social-menu ul li a:hover {
    transform: translate(0, -10%);
    border: 2px solid #2B2B2B;
    outline: 2px solid white;
    filter: drop-shadow(0px 0px 8px #FFF);
}

.social-menu ul li:nth-child(1) a:hover {
    background-color: rgba(0, 0, 0, 0.829);
}

.social-menu ul li:nth-child(2) a:hover {
    background-color: #E4405F;
}

.social-menu ul li:nth-child(3) a:hover {
    background-color: #0077b5;
}

.social-menu ul li:nth-child(4) a:hover {
    background-color: #000;
}

.footer-pages {
    display: flex;
    justify-content: space-between;
}


.footer-pages li {
    position: relative;
}

.footer-pages li::after {
    content: "|";
    position: absolute;
    right: -10px;
}

.footer-pages li:last-child:after {
    content: "";
}

.footer-pages a {
    color: #fff;
    text-decoration: none;
}

.social-menu::before {
    content: "";
    position: absolute;
    left: 70px;
    width: 30%;
    height: 3px;
    background-color: #8D8080;
}

.social-menu::after {
    content: "";
    position: absolute;
    right: 70px;
    width: 30%;
    height: 3px;
    background-color: #8D8080;
}

.footer-btm {
    display: flex;
    width: 100%;
    margin: unset;
    justify-content: center;
    align-items: center;
    background: #F6F3EE;
    height: 55px;
    
}
.footer-btm p {
    margin: 0;
    color: #454545;
    font-family: "Brown";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
}

.footer-col-1 { 
    padding: 0 0 !important;
}

.footer-mobile { 
    display: none;
}

.footer .content-btn { 
    width: 37%;
    min-width: unset;
    max-width: unset;
}

@media screen and (max-width:1200px) { 
    .footer .container { 
        /* max-width: 1200px; */
    }
    .footer ul li {
        font-size: 13px;
    }
    .footer, .footer span, .footer p, .footer h5 {
        font-family: "source-sans-pro";
        font-size: 13px;
    }
    .footer h3 {
        font-size: 22px;
    }
}

@media only screen and (max-width: 991px) {
    .footer-web { 
        display: none;
    }
    .footer-mobile {
        display: block;
    }
    .footer-mobile .logo { 
        width: 200px;
    }
    .footer h3 {
        font-size: 15px;
    }
    .subscribe-input {
        justify-content: center;
    }
    .footer input {
        margin-right: unset;
        height: 35px;
        width: 95%;
        margin-bottom: 10px;
    }
    .footer .content-btn {
        min-width: 170px;
        max-width: 287px;
        height: 45px;
    }
    .footer-col-1 {
        padding: 0 0 0 20px !important;
        width: 45%;
    }
    .footer p {
        font-size: 12px;
        font-family: 'Sintony', sans-serif;
        color: #B1B1B1;    
    }
    .social-menu::before {
        display: none;
    }
    .social-menu::after { 
        display: none;
    }
    .social-menu {
        transform: rotate(90deg);
        position: absolute !important;
        transform-origin: right;
        bottom: -70px;
        right: 20px;
    }
    .social-menu img{
        transform: rotate(-90deg);
    }
    .social-menu ul li{ 
        margin: 0 22px;
    }
    .social-menu ul li a {
        width: 32px;
        height: 32px;
        box-shadow: unset;
        outline: unset;
        border: unset;
    }
    .footer-pages {
        display: flex;
        justify-content: space-evenly;
    }
    .footer-pages li::after {
        right: -10px;
    }
    .footer-btm p {
        font-size: 13px;
        text-align: center;
    }
    .footer-col-info {
        margin-bottom: 10px !important;
    }
    .footer-pages { 
        width: 100%;
    }
    .footer-btm p {
        display: block !important;
        width: 100%;
        font-size: 13px;
        text-align: center;
        font-family: Brown;
        font-size: 12px;
        color: black;
    }
    .footer ul li {
        list-style-type: none;
        font-size: 12px;
        margin-bottom: 1px;
        text-transform: capitalize;
        font-family: 'Sintony', sans-serif;
        color: #B1B1B1;
    }
    
    .footer address {
            color: #B1B1B1;
            font-family: 'Sintony', sans-serif;
            font-size: 12px;
    }
    .footer span a {
        color: #B1B1B1;
        font-family: 'Sintony', sans-serif;
        font-size: 12px;
    }
    .footer span {
        font-size: 12px;
        margin-top: 5px;
    }
    .footer-mobile-row {
        padding: 20px 0 !important;
    }
    
.footer h4 {
    font-size: 14px;
    font-family: "Brown";
}
}

@media screen and (max-width: 768px) {
    .social-menu {
        right: 40px;
    }
}

@media screen and (max-width: 480px) {
    .subscribe-input form {
        width: 90%;
    }
}