/* .single-product-wrapper .slick-slider  img {  
    width: 100% !important;
    /* height: 120px !important; 
} */

.single-product-wrapper .slick-arrow {
    width: 40px !important;
    height: 40px !important;
}

.single-product-wrapper .slick-arrow.slick-prev::before {
    content: '';
    background-image: url('../../assets/images/arrowLeft.png') !important;
    width: 40px;
    position: absolute;
    height: 40px;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(90deg);
}

.single-product-wrapper .slick-arrow.slick-next::before {
    content: '';
    background-image: url('../../assets/images/arrowRight.png') !important;
    width: 40px;
    position: absolute;
    height: 40px;
    top: 0;
    left: 0;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(90deg);
}

.single-product-wrapper .slick-prev {
    top: -50px;
    left: 30%;
}

.single-product-wrapper .slick-next {
    top: unset;
    bottom: -100px;
    left: 30%;
    right: unset;
}

.single-product-wrapper .large-preview-product-img img {
    max-width: 100%;
    height: 100%;
    object-fit: unset;
}

.large-preview-product-img {
    border: 1px solid  #000;
    display: flex;
    height: 520px;
    justify-content: center;
}

span.img_count {
    background: #454545;
    color: #FFFFFF;
    font-family: 'source-sans-pro';
    box-shadow: 0px 45px 38px 0px #0000001F;
    display: flex;
    width: 50px;
    height: 25px;
    align-items: center;
    justify-content: center;
    border-radius: 24px;
    position: absolute;
    bottom: 15px;
    font-size: 14px;
}

.single-product-wrapper .slick-slide {
    border: 1px solid transparent;
}

/* .single-product-wrapper .slick-slide.slick-active.slick-current { 
    border: 1px solid #000;
} */

.slick-track {
    height: unset !important;
}

.single-product-wrapper .vertical-carousel-item {
    border: 1px solid transparent;
    height: 100px !important;
    display: flex !important;
    align-items: center;
}

.single-product-wrapper .vertical-carousel-item img {
    height: 100%;
    /* object-fit: contain; */
}

.single-product-wrapper .vertical-carousel-item.selected {
    border: 1px solid #000;
}

.single-product-wrapper .slick-slide .vertical-carousel-item img {
    width: 100%;
}

.sp-wrapper-lft {
    /* padding-top: 65px; */
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
}

/* .sp-wrapper-lft::before {
    content: "";
    background-image: url('../../assets/images/arrowLeft.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    position: absolute;
    top: 0;
    border-radius: 50%;
    object-fit: contain;
    transform: rotate(90deg);
    cursor: pointer;
}

.sp-wrapper-lft::after {
    content: "";
    background-image: url('../../assets/images/arrowLeft.png');
    background-size: cover;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 0;
    border-radius: 50%;
    object-fit: contain;
    transform: rotate(-90deg);
    cursor: pointer;
}
 */

@media screen and (max-width:767px) {
    .sp-wrapper-lft {
        width: 100%;
        padding-top: unset;
        display: unset;
    }

    .slick-slide img {
        width: 100%;
    }

    .single-product-wrapper .slick-next {
        display: none !important;
    }
    .single-product-wrapper .slick-prev { 
        display: none !important;
    }
}